import { Injectable, Signal, WritableSignal } from '@angular/core';
import { CollectionFragment, GetCollections } from '@api/generated-types';
import { GET_COLLECTIONS } from '@api/vendure/documents.graphql';
import { DataService } from '@core/providers/data/data.service';
import { createCacheSignal } from '@core/utils/CacheSignal';
import { Logger } from '@core/utils/logger';
import { lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';

const logger = new Logger('CollectionService');

@Injectable({
    providedIn: 'root',
})
export class CollectionService {
    private collections$: WritableSignal<CollectionFragment[]>;

    constructor(private dataService: DataService) {
        this.collections$ = createCacheSignal([], 5 * 60 * 1000, this.fetchCollections.bind(this));
    }

    /**
     * Fetches the collections from the server.
     */
    async fetchCollections(): Promise<CollectionFragment[]> {
        logger.info('Fetching collections');
        const result = await lastValueFrom(
            this.dataService.query<GetCollections.Query, GetCollections.Variables>(GET_COLLECTIONS).pipe(
                map(data =>
                    data.collections.items.filter(
                        (collection: any) => collection.parent && collection.parent.name === '__root_collection__',
                    ),
                ),
                take(1),
            ),
        );
        return result || [];
    }

    /**
     * Returns a readonly signal of the collections.
     */
    get collections(): Signal<CollectionFragment[]> {
        return this.collections$.asReadonly();
    }

    /**
     * Refreshes the collections.
     */
    async refresh() {
        logger.debug('Refreshing collections');
        const value = await this.fetchCollections();
        this.collections$.set(value);
    }
}
